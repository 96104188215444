@tailwind base;
@tailwind components;
@tailwind utilities;
.tabs .tab {
  line-height: 1rem;
}
/* =========== Custom Styles for Transcripts =========== */
.raw-transcript > article > h1:first-of-type {
  display: none;
}

.raw-transcript > article > h2:first-of-type {
  margin-top: 0;
}

.prose blockquote p:first-of-type {
  margin-top: 0;
}
.prose table thead th {
  text-align: left;
}

/* =========== Custom Styles for Quartr Transcripts =========== */
/* Hide the Quartr Transcript Attribution when the formatting for the transcript matches the Finnhub formatting */
.powered-by-quartr:has(+ .raw-transcript > article > h1:first-child + h2) {
  display: none;
}

/* =========== Custom Keyframe Animations =========== */

@keyframes shrink-header {
  to {
    padding-bottom: 0rem;
  }
}
@keyframes shrink-header-desktop {
  to {
    padding-top: 0.75rem;
    padding-bottom: 0rem;
  }
}

@keyframes shrink-header-text {
  to {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@keyframes shrink-header-tabs {
  to {
    margin-top: 0rem;
  }
}

/* These are styles for a default DaisyUI Small Button to transition to */
@keyframes shrink-header-new-btn {
  to {
    height: 2rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    min-height: 2rem;
    font-size: 0.875rem;
  }
}

/* =========== End Custom Keyframe Animations =========== */

#sticky-header {
  padding-bottom: 1rem;
  animation: shrink-header linear both;
}

#sticky-header h1 {
  animation: shrink-header-text linear both;
}

#sticky-header .tabs {
  margin-top: 8px;
  animation: shrink-header-tabs linear both;
}

#sticky-header .start-new-session-btn {
  animation: shrink-header-new-btn linear both;
}

#sticky-header,
#sticky-header h1,
#sticky-header .tabs,
#sticky-header .start-new-session-btn {
  animation-timeline: scroll();
  animation-range: 0 250px;
}

/* ========== Media Query Styles ============== */

/* Tailwind's Large Breakpoint */
@media (min-width: 1024px) {
  #sticky-header {
    padding-bottom: 2rem;
    animation: shrink-header-desktop linear both;
  }
}

/* ========== End Media Query Styles ============== */

.PopoverContent {
  width: var(--radix-popover-trigger-width) !important;
  max-height: var(--radix-popover-content-available-height);
}

/* =========== Custom Styles for Sentry Feedback =========== */
#sentry-feedback {
  display: none;
}

@media screen and (min-width: 1024px) {
  #sentry-feedback {
    display: block;
    bottom: 75px;
  }
}
/* =========== End Custom Styles for Sentry Feedback =========== */

/* Tailwind Typography Overrides */
.prose blockquote p:first-of-type::before,
.prose blockquote p:last-of-type::after {
  content: none;
}

.prose
  :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: 400;
  font-style: normal;
}
.prose pre:has(canvas) {
  background: white;
}
